/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-split-pane {
  --side-max-width: 15%;
  --side-min-width: 12%;
}

ion-input.custom {
  --placeholder-opacity: 0.8;
  --padding-bottom: 0px;
  --padding-end: 4px;
  --padding-start: 10px;
  --padding-left: 0px;
  --padding-top: 0px;
  margin-top: 10px;
  margin-left: 4px;
  margin-bottom: 0px;
  margin-right: 4px;
}

ion-label.custom {
  padding-left: 4px;
  z-index: 1;
  margin-top: 4px;
  margin-bottom: 4px;
}

ion-col.custlbl {
  text-align: right;
  background: var(--ion-color-light-shade);
  border: solid 1px var(--ion-color-light-tint);
  --color: var(--ion-color-dark);
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}
ion-col.custtxt {
  --background: var(--ion-color-light-shade);
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  border: solid 1px var(--ion-color-light-tint);
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}

ion-col.custheader {
  text-align: left;
  --background: var(--ion-color-dark-shade);
  background: var(--ion-color-light-shade);
  border: solid 1px var(--ion-color-light-tint);
  --color: var(--ion-color-light);
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}
ion-col.custcel {
  text-align: left;
  --background: var(--ion-color-light-shade);
  border: solid 1px var(--ion-color-light-tint);
  --color: #fff;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
}

ion-label.lbl{
  color: var(--ion-color-primary);
}

ion-grid {
  --ion-grid-padding: 4px;

  --ion-grid-padding-xs: 4px;
  --ion-grid-padding-sm: 4px;
  --ion-grid-padding-md: 4px;
  --ion-grid-padding-lg: 4px;
  --ion-grid-padding-xl: 4px;

  --ion-grid-column-padding: 4px;

  --ion-grid-column-padding-xs: 4px;
  --ion-grid-column-padding-sm: 4px;
  --ion-grid-column-padding-md: 4px;
  --ion-grid-column-padding-lg: 4px;
  --ion-grid-column-padding-xl: 4px;
}



[hidden] {
  display: none !important;
}
